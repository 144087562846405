jQuery(document).ready(function () {
	jQuery(".bxslider").bxSlider({
		pager: false,
        controls: false,
        auto: (jQuery(".bxslider").children().length < 2) ? false : true,
	    touchEnabled: true,
		adaptiveHeight: true,
        speed: 500,
        pause: 6500,
        keyboardEnabled: true,
        useCSS: false
    });

    jQuery(".mobile-icon").on("click touchstart", function (event) {
        event.preventDefault();
		event.stopPropagation();
		jQuery("body").toggleClass("open-menu");
    });

    jQuery(document).on("click touchstart", function () {
        jQuery("body").removeClass("open-menu");
    });

     jQuery(".site-header .col-sm-9.text-right").on("click touchstart", function (event) {
		event.stopPropagation();
    });

    jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){

	   if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
	   {
		event.preventDefault();
		event.stopPropagation();
		jQuery(this).parent().addClass("open").siblings().removeClass("open");
	   }
    });

    var footerHeight = jQuery(".site-footer").outerHeight();
    var container = jQuery(".content-section .editor-block").height();
    var sidebar = jQuery(".sidebar-block").height();

    if( container > sidebar && jQuery(window).width() > 1199 ){

        jQuery.stickysidebarscroll(".sidebar-block",{offset: {top:0, bottom:footerHeight}});
    }

    equalheight(".view-job-block .job-descreption");

});

jQuery(window).load(function(){
    equalheight(".view-job-block .job-descreption");
});

jQuery(window).resize(function(){
	equalheight(".view-job-block .job-descreption");
});



equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel;

        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight("auto");
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height("auto");
    }
};
